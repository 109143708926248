import React from 'react';
import { Stack, media } from '@tymate/margaret';
import styled, { css } from 'styled-components';
import { Container, Title } from 'ui';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { MdChevronRight } from 'react-icons/md';

const Cover = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;

  ${({ layout }) =>
    layout === 'odd' &&
    css`
      ${media.tablet`
        grid-column: 2;
      `};
    `}

  > * {
    height: 100%;
  }
`;

const Content = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;

  ${({ layout }) =>
    layout === 'odd' &&
    css`
      ${media.tablet`
        grid-column: 1;
      `};
    `}
`;

const Icon = styled.img``;

const Specialties = styled.ul`
  margin: 0;
  margin-top: ${({ theme }) => theme.spacing(2)};
  padding: 0;
  list-style-type: none;
  color: #ffffff;

  li + li {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

const Specialty = styled.li`
  color: ${({ theme }) => theme.textOnDarkLighter};
  transition: color 150ms ease;

  &:hover {
    color: ${({ theme }) => theme.textOnDark};
  }

  ${Link} a {
    color: inherit;
    text-decoration: none;
  }
`;

const Right = ({ right, layout, variant }) => {
  const cover = getImage(right.cover);
  const backgroundImage = convertToBgImage(cover);

  return (
    <>
      {variant !== 'home' && (
        <Cover layout={layout}>
          <BackgroundImage {...backgroundImage} preserveStackingContext />
        </Cover>
      )}

      <Content layout={layout} variant={variant}>
        <Container size="full">
          <Stack
            direction="column"
            alignX="center"
            size="full"
            paddingVertical={2}
            gutterSize={1}
          >
            <Icon src={right.icon?.url} alt="" />
            <Title variant="light">{right.name}</Title>
            <Specialties>
              {(right.specialties || []).map(({ name, slug }) => (
                <Specialty key={slug}>
                  <MdChevronRight />{' '}
                  <Link
                    to={`${process.env.GATSBY_SITE_URL}/expertises/${right.slug}/${slug}`}
                  >
                    {name}
                  </Link>
                </Specialty>
              ))}
            </Specialties>
          </Stack>
        </Container>
      </Content>
    </>
  );
};

export default Right;
